import { mapActions, mapGetters, mapMutations } from 'vuex'
import validationMixin from '@/mixins/validation'
import { debounce, snakeCase } from 'lodash'
import { userCourseListTableHead } from '../../../admins/helpers'

export default {
    name    : 'user-students-courses',
    mixins  : [validationMixin],
    data () {
        return {
            valid                   : false,
            validUpdateProgressForm : false,
            page                    : 1,
            showDialogCourse        : false,
            showUpdateDialog        : false,
            showRemoveDialog        : false,
            showDialogUpdateProgress: false,
            validFormCourse         : false,
            deletedItemData         : null,
            editedItem              : null,
            updateItemData          : null,
            keywords                : '',
            payload                 : {
                courseId    : '',
                packageId   : ''
            },
            progressForm            : {
                userId      : this.$route.params.id,
                moduleId    : '',
                packageId   : '',
                courseUserId: '',
                id          : '',
                sort        : ''
            },
            search                  : null,
            searchInput             : null,
            progressFormSort        : null,
            spinnerUpdate           : []
        }
    },
    computed: {
        ...mapGetters({
            userCourses             : 'userCourses/userCourses',
            userCoursesLength       : 'userCourses/userCoursesLength',
            isUserCoursesLoading    : 'userCourses/isUserCoursesLoading',
            limit                   : 'userCourses/limit',
            skip                    : 'userCourses/skip',
            isLoading               : 'userCourses/isLoading',
            courses                 : 'userCourses/courses',
            isCoursesLoading        : 'userCourses/isCoursesLoading',
            packagesCourse          : 'userCourses/packagesCourse',
            isPackagesCourseLoading : 'userCourses/isPackagesCourseLoading',
            courseModules           : 'courses/courseModules',
            courseModulesLoading    : 'courses/courseModulesLoading',
            courseMaterials         : 'courses/courseMaterials',
            courseMaterialsLoading  : 'courses/courseMaterialsLoading'
        }),
        userCourseListTableHead
    },
    created () {
        if (!this.userCourses.length) {
            this.fetchUserCourses({
                userId  : this.$route.params.id,
                keywords: this.keywords
            })
        }
        
        if (this.userCourses) {
            if (this.skip !== 0) {
                this.page = Math.round(this.skip / this.limit) + 1
            }
        }
    },
    methods : {
        ...mapActions({
            fetchCourses        : 'userCourses/GET_COURSES',
            fetchPackages       : 'userCourses/PACKAGES_GET_LIST',
            fetchUserCourses    : 'userCourses/GET_USER_COURSES',
            fetchCourseModules  : 'courses/GET_COURSE_MODULES',
            fetchCourseMaterials: 'courses/GET_COURSE_MATERIALS',
            remove              : 'userCourses/DELETE',
            update              : 'userCourses/UPDATE',
            updateProgress      : 'userCourses/UPDATE_PROGRESS',
            create              : 'userCourses/CREATE',
            summary             : 'userCourses/SUMMARY',
        }),
        ...mapMutations({
            changeSkip          : 'userCourses/USER_COURSES_CHANGE_SKIP',
            setUserCourses      : 'userCourses/SET_USER_COURSES',
            setCourses          : 'userCourses/SET_COURSES',
            setPackages         : 'userCourses/PACKAGES_SET_LIST'
        }),
        changeInputCourse: debounce(function (val) {
            if (!val) return;
            
            if (this.keywords !== val) {
                this.keywords = val;
                
                this.fetchCourses({
                    keywords: val,
                    id      : this.payload.courseId
                })
            }
        }, 500),
        autoCompleteChanged (e) {
            this.setPackages([]);
            this.payload.courseId = e.id;
            this.search = e;
            
            this.fetchPackages({
                courseId: e.id
            })
        },
        // end //
        changeDialogCourse (item = null, status = false) {
            this.setPackages([]);
            
            this.showDialogCourse   = status;
            this.editedItem         = item;
            
            if (status) {
                if (item) {
                    this.payload.packageId = item.package.data.id;
                    
                    this.fetchPackages({
                        courseId: item.course.data.id
                    })
                } else {
                    this.setCourses([]);
                    
                    this.fetchCourses({
                        keywords: ''
                    });
                }
            } else {
                this.payload.courseId   = '';
                this.payload.packageId  = '';
                
                this.search             = null;
                this.searchInput        = null;
            }
        },
        removeItem () {
            this.remove({
                userId  : this.$route.params.id,
                id      : this.deletedItemData.id
            })
            .then(() => {
                this.showRemoveDialog = false;
                
                this.$toasted.success(this.$t('success_deleted'))
            })
        },
        changeDialogDelete (item = null) {
            this.showRemoveDialog   = !!item;
            this.deletedItemData    = item;
        },
        changeDialogUpdate (item = null, status = false) {
            const index = this.spinnerUpdate.indexOf(item.id);
            
            if(index != -1){
                return;
            }
            
            this.showUpdateDialog   = status;
            this.updateItemData     = item;
        },
        changeDialogUpdateProgress (status = false, item = null) {
            this.showDialogUpdateProgress   = status;
            this.progressForm.userId        = this.$route.params.id;
            
            if (item) {
                // eslint-disable-next-line camelcase
                const { id, module_id, sort } = item.currentMaterial.data;
                
                this.progressForm.courseUserId = item.id
                this.progressForm.courseId = item.course_id
                this.progressForm.moduleId = module_id
                this.progressForm.id = id
                this.progressForm.packageId = item.package_id
                this.progressForm.sort = sort
                
                this.fetchCourseModules(this.progressForm);
                
                if (item.currentMaterial.data.hasOwnProperty('id')) {
                    this.fetchCourseMaterials(this.progressForm)
                }
            } else {
                Object.keys(this.progressForm).forEach(e => {
                    this.progressForm[e] = ''
                })
            }
        },
        changeSelectUpdateProgressMaterials (progressForm) {
            this.progressForm.sort = progressForm
        },
        updateProgressCourse () {
            this.$refs.updateProgressForm.validate();
            
            if (this.validUpdateProgressForm) {
                this.progressForm.lesson_id = this.progressForm.id;
                
                this.updateProgress(this.progressForm).then(() => {
                    this.$toasted.success(this.$t('success_updated'));
                    this.changeDialogUpdateProgress();
                    
                    this.fetchUserCourses({
                        userId  : this.$route.params.id,
                        keywords: this.keywords
                    })
                })
            }
        },
        updateItem () {
            if(!this.updateItemData){
                this.showUpdateDialog   = false;
                
                return;
            }
            
            const index = this.spinnerUpdate.indexOf(this.updateItemData.id);
            
            if(index != -1){
                this.showUpdateDialog   = false;
                
                return;
            }
            
            this.spinnerUpdate.push(this.updateItemData.id);
            
            this.showUpdateDialog   = false;
            
            console.log('index:', index);
            
            console.log('id:', this.updateItemData.id);
            console.log('course_id:', this.updateItemData.course_id);
            console.log('user_id:', this.updateItemData.user_id);
            
            this.summary({
                courseId    : this.updateItemData.course_id,
                userId      : this.updateItemData.user_id
            })
            .then((result) => {
                console.log('then:', result);
                
                var ids = [];
                
                for(let i = 0; i < this.spinnerUpdate.length; i++){
                    if(this.spinnerUpdate[i] != this.updateItemData.id){
                        ids.push(this.spinnerUpdate[i]);
                    }
                }
                
                this.spinnerUpdate = ids;
                
                if(result){
                    this.$toasted.success(this.$t('success'));
                }
            })
            .catch(() => {
                console.log('catch');
                
                var ids = [];
                
                for(let i = 0; i < this.spinnerUpdate.length; i++){
                    if(this.spinnerUpdate[i] != this.updateItemData.id){
                        ids.push(this.spinnerUpdate[i]);
                    }
                }
                
                this.spinnerUpdate = ids;
            });
        },
        changePagination (index) {
            this.changeSkip(this.limit * (index - 1));
            this.$vuetify.goTo(0);
            
            this.fetchUserCourses({
                userId: this.$route.params.id
            })
        },
        createRequestPayload () {
            const formData = {};
            
            for (const i in this.payload) {
                formData[snakeCase(i)] = this.payload[i];
            }
            
            formData.userId = this.$route.params.id;
            
            if (this.editedItem) {
                formData._method = 'patch';
                formData.id = this.editedItem.id;
            }
            
            return formData;
        },
        submit () {
            this.$refs.formCourse.validate();
            
            if (this.validFormCourse) {
                if (this.editedItem) {
                    this.update(this.createRequestPayload()).then(() => {
                        this.$toasted.success(this.$t('success_updated'))
                        this.changeDialogCourse()
                    })
                } else {
                    this.create(this.createRequestPayload()).then(() => {
                        this.$toasted.success(this.$t('success_created'))
                        this.changeDialogCourse()
                    })
                }
            }
        }
    },
    destroyed () {
        this.setUserCourses([])
    }
}
